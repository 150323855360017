import { URL_APP_SEARCH } from '../../utils/Constants';
import { PrivateApi } from '../PrivateApi';
import { AppSearch } from '../models/AppModels';

export class GeneralAPIs extends PrivateApi {
  appSearch(q: string) {
    return new Promise((resolve, reject) => {
      this.get(`${URL_APP_SEARCH}?${q}`, this.getAuthenticatedHeaders())
        .then((response: any) => {
          const results: AppSearch = new AppSearch(response);
          resolve(results);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }
}
