// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

import {
  COLOR_BLUE,
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_RED,
} from '../../utils/Constants';

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          color: COLOR_LIGHT,
          backgroundColor: COLOR_DARK,
          border: `1px solid ${COLOR_LIGHT}`,
        },
        listbox: {
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: COLOR_RED,
            background: COLOR_RED,
            color: COLOR_LIGHT,
          },
          '& .MuiAutocomplete-groupLabel': {
            // Style for the group header
            backgroundColor: COLOR_BLUE,
            color: COLOR_LIGHT,
            lineHeight: '34px',
          },
        },
      },
    },
  };
}
