import Box from '@mui/material/Box';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  COLOR_BLUE,
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_ORANGE,
} from '../../utils/Constants';
import SidebarDrawer from './SidebarDrawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Collapse, CssBaseline, IconButton, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/Logo_MAGEnergy_Light.png';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HeaderAutoCompleteSearch from '../../components/form-components/HeaderAutoCompleteSearch';
import Profile from './profile';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function MainLayout(props: any) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        open={isDrawerOpen}
        sx={{
          // width: '100%',
          backgroundColor:
            process.env.REACT_APP_ENV === 'staging' ? COLOR_ORANGE : COLOR_BLUE,
          borderBottom: `1px solid ${COLOR_BLUE}`,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: '#ffffff',
                mr: 2,
                ...(isDrawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                width: 'auto',
                height: '44px',
                '@media (max-width: 600px)': { width: '140px' },
              }}
            >
              <img
                src={logo}
                alt={'logo'}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </Stack>
          <ClickAwayListener
            onClickAway={() => {
              setIsOpenSearchDialog(false);
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                width: '70%',
                m: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                aria-label="search"
                sx={{ color: COLOR_LIGHT }}
                onClick={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
              >
                {!isOpenSearchDialog && <SearchOutlinedIcon />}
              </IconButton>
              <Collapse in={isOpenSearchDialog}>
                {isOpenSearchDialog && <HeaderAutoCompleteSearch />}
              </Collapse>
            </Box>
          </ClickAwayListener>
          <Profile />
        </Toolbar>
      </AppBar>
      <SidebarDrawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        CustomDrawer={Drawer}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: COLOR_DARK,
          px: 2,
          py: 0.5,
          minHeight: '100vh',
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <DrawerHeader />

        <Outlet />
      </Box>
    </Box>
  );
}

export default MainLayout;
