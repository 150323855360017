import { Avatar } from '@mui/material';

function ProfileAvatar(props: any) {
  const { src, name, avatarSx } = props;

  const stringAvatar = (name: string) => {
    return {
      sx: {
        ...avatarSx,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };
  return (
    <>
      {src ? (
        <Avatar alt={name} src={src} sx={{ ...avatarSx }} />
      ) : (
        <Avatar {...stringAvatar(name)} />
      )}
    </>
  );
}

export default ProfileAvatar;
