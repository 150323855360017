import Cookies from 'js-cookie';
import { NetworkRequests } from './NetWorkRequests';

export class PublicApi extends NetworkRequests {
  private readonly unauthenticatedHeaders: any;

  constructor() {
    super();
    this.unauthenticatedHeaders = {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    };
  }

  getUnauthenticatedHeaders() {
    return this.unauthenticatedHeaders;
  }
}
