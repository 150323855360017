import { useState } from 'react';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  COLOR_LIGHT,
  navButtonStyles,
  PROFILE_PATH,
} from '../../../utils/Constants';

const ProfileTab = (props: any) => {
  const { handleLogout, user } = props;
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event: any, index: number) => {
    setSelectedIndex(index);
    if (user?.id) {
      navigate(PROFILE_PATH.replace(':id', user?.id));
    }
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 32,
        },
      }}
    >
      <ListItemButton
        sx={navButtonStyles}
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
      >
        <ListItemIcon sx={{ color: COLOR_LIGHT }}>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      <ListItemButton
        sx={navButtonStyles}
        selected={selectedIndex === 2}
        onClick={handleLogout}
      >
        <ListItemIcon sx={navButtonStyles}>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
