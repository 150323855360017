// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        // root:{
        //     color: COLOR_LIGHT
        // },
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  };
}
