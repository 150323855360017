import { URL_COMMENTS, URL_USER_MENTIONS } from '../../utils/Constants';
import { PrivateApi } from '../PrivateApi';
import { Comment, RecurringRequest, UserMention } from '../models/AppModels';
import HelperFunctions from '../../utils/HelperFunctions';

export class CommentAPIs extends PrivateApi {
  getComments(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_COMMENTS}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          if (params?.disable_pagination) {
            const comments = response?.map(
              (item: Comment) => new Comment(item)
            );
            resolve(comments);
          } else {
            const comments: Comment[] = response?.results?.map(
              (item: Comment) => new Comment(item)
            );
            localStorage['commentsSize'] = response?.count;
            resolve({
              data: comments,
              page:
                comments?.length > 0 && response?.next
                  ? params?.page + 1
                  : undefined,
            });
          }
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getCommentMentions(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_USER_MENTIONS}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const userMentions: UserMention[] = response?.results?.map(
            (item: UserMention) => new UserMention(item)
          );

          resolve({
            data: userMentions,
            page:
              userMentions?.length > 0 && response?.next
                ? params?.page + 1
                : undefined,
          });
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getCommentById(CommentId: string) {
    return new Promise((resolve, reject) => {
      this.get(`${URL_COMMENTS}${CommentId}/`, this.getAuthenticatedHeaders())
        .then((response: any) => {
          resolve(new Comment(response));
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  addComment(
    comment: Comment,
    callback: (is_successful: boolean, model_or_error: Comment | string) => void
  ) {
    this.post(`${URL_COMMENTS}`, { ...comment }, this.getAuthenticatedHeaders())
      .then((response: any) => {
        const comment: Comment = new Comment(response);
        callback(true, comment);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  updateComment(
    comment: Comment,
    callback: (is_successful: boolean, model_or_error: Comment | string) => void
  ) {
    this.patch(
      `${URL_COMMENTS}${comment?.id}/`,
      { ...comment },
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        const comment: Comment = new Comment(response);
        callback(true, comment);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  markReadCommentById(
    ids: string[],
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.get(
      `${URL_COMMENTS}mark-comments-read/?pks=${ids.join(',')}`,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  commentActions(
    body: any,
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.post(
      `${URL_COMMENTS}recurring-requests/`,
      body,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        const comment: Comment = new Comment(response);
        callback(true, comment);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  commentActionsPatch(
    body: any,
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.patch(
      `${URL_COMMENTS}recurring-requests-patch/`,
      body,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        const comment: Comment = new Comment(response);
        callback(true, comment);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  commentActionsGet(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_COMMENTS}recurring-requests-get/${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const recurringRequests: RecurringRequest[] = response?.map(
            (item: RecurringRequest) => new RecurringRequest(item)
          );

          resolve(recurringRequests);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  deleteCommentById(
    ids: string[],
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.delete(
      `${URL_COMMENTS}delete-many/?pks=${ids.join(',')}`,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }
}
