import { Typography } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layouts/MainLayout';
import AuthenticatedUserGuard from '../network/AuthenticatedUserGuard';
import { AppContextProvider } from '../utils/AppStateContext';
import {
  APP_PATH,
  COLOR_LIGHT,
  CUSTOMERS_PATH,
  KANBAN_BOARDS_PATH,
  NOT_FOUND_PATH,
  NOTIFICATIONS_PATH,
  PROFILE_PATH,
  PROJECTS_PATH,
  REPORTS_PATH,
  ROOT_PATH,
  SETUPS_PATH,
  SITES_PATH,
  TABLE_VIEWS_PATH,
  TASKS_PATH,
  TDC_TESTS_PATH,
} from '../utils/Constants';

const DashboardDefault = Loadable(lazy(() => import('../pages/Dashboard')));
const Profile = Loadable(lazy(() => import('../pages/account/Profile')));
const Customers = Loadable(lazy(() => import('../pages/Customers')));
const Projects = Loadable(lazy(() => import('../pages/Projects')));
const Setups = Loadable(lazy(() => import('../pages/Setups')));
const Sites = Loadable(lazy(() => import('../pages/Sites')));
const Tasks = Loadable(lazy(() => import('../pages/Tasks')));
const Notifications = Loadable(lazy(() => import('../pages/Notifications')));
const Reports = Loadable(lazy(() => import('../pages/Reports')));
const KanbanBoards = Loadable(lazy(() => import('../pages/KanbanBoards')));
const TableViews = Loadable(lazy(() => import('../pages/TableViews')));
const TDCTests = Loadable(lazy(() => import('../pages/TDCTests')));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const mainWrapper = (
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <MainLayout />
    </AppContextProvider>
  </QueryClientProvider>
);
const MainRoutes = {
  path: ROOT_PATH,
  element: mainWrapper,
  children: [
    {
      path: NOT_FOUND_PATH,
      element: (
        <Typography
          variant="h4"
          sx={{ color: COLOR_LIGHT, textAlign: 'center' }}
        >
          404 not found
        </Typography>
      ),
    },
    {
      path: APP_PATH,
      element: <AuthenticatedUserGuard component={<DashboardDefault />} />,
      // element: (
      //   <AuthenticatedUserGuard
      //     component={
      //
      //         <HighLevel />
      //
      //     }
      //   />
      // ),
    },
    {
      path: ROOT_PATH, //dev purposes
      element: <AuthenticatedUserGuard component={<DashboardDefault />} />,
      // element: (
      //   <AuthenticatedUserGuard
      //     component={
      //
      //         <HighLevel />
      //
      //     }
      //   />
      // ),
    },
    {
      path: PROFILE_PATH,
      element: <AuthenticatedUserGuard component={<Profile />} />,
    },
    {
      path: CUSTOMERS_PATH,
      element: <AuthenticatedUserGuard component={<Customers />} />,
    },
    // {
    //   path: HIGH_LEVEL_PATH,
    //   element: (
    //     <AuthenticatedUserGuard
    //       component={
    //
    //           <HighLevel />
    //
    //       }
    //     />
    //   ),
    // },
    {
      path: PROJECTS_PATH,
      element: <AuthenticatedUserGuard component={<Projects />} />,
    },
    {
      path: SITES_PATH,
      element: <AuthenticatedUserGuard component={<Sites />} />,
    },
    {
      path: TASKS_PATH,
      element: <AuthenticatedUserGuard component={<Tasks />} />,
    },
    {
      path: SETUPS_PATH,
      element: <AuthenticatedUserGuard component={<Setups />} />,
    },
    {
      path: NOTIFICATIONS_PATH,
      element: <AuthenticatedUserGuard component={<Notifications />} />,
    },
    {
      path: REPORTS_PATH,
      element: <AuthenticatedUserGuard component={<Reports />} />,
    },
    {
      path: KANBAN_BOARDS_PATH,
      element: <AuthenticatedUserGuard component={<KanbanBoards />} />,
    },
    {
      path: TABLE_VIEWS_PATH,
      element: <AuthenticatedUserGuard component={<TableViews />} />,
    },
    {
      path: TDC_TESTS_PATH,
      element: <AuthenticatedUserGuard component={<TDCTests />} />,
    },
  ],
};

export default MainRoutes;
