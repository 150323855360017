import React, { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_PATH, LOGIN_PATH, NOT_ALLOWED_PATH } from '../utils/Constants';
import { AuthActions } from './AuthActions';

function AuthenticatedUserGuard(props: any) {
  const { component } = props;
  const accountActions: any = useMemo(() => new AuthActions(), []);
  const navigate = useNavigate();
  const pathNameRef = useRef(window.location.pathname);
  if (!accountActions.isAuthenticated()) {
    if (pathNameRef.current === NOT_ALLOWED_PATH) {
      pathNameRef.current = APP_PATH;
    }
    navigate(`${LOGIN_PATH}/?next=${pathNameRef.current}`, {
      replace: true,
    });
  }

  return <React.Fragment>{component}</React.Fragment>;
}

export default AuthenticatedUserGuard;
