import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { LOGIN_PATH, URL_REFRESH_TOKENS } from '../utils/Constants';
import { AuthenticationTokens } from './models/AccountModels';
import { AuthActions } from './AuthActions';
import Cookies from 'js-cookie';

export class NetworkRequests {
  service;
  authenticationActions_: any;

  constructor(customParams = false) {
    this.service = axios.create({
      params: customParams,
    });
    this.service.interceptors.response.use(this.handleSuccess);
    this.authenticationActions_ = new AuthActions();
  }

  // default handle success function
  handleSuccess(response: AxiosResponse) {
    return response.data;
  }

  // default handle error function
  handleError(error: AxiosError, callback: any) {
    if (error?.response?.status === 401) {
      return this.refreshTokenRequest(callback);
    } else if (error?.response?.status === 500) {
      const response = {
        ...error?.response,
        data: 'Server error. Please try again after some time.',
      };
      throw { ...error, response: response };
    } else {
      throw error;
    }
  }

  async refreshTokenRequest(callback: any) {
    const tokens: any =
      this.authenticationActions_.retrieveAuthenticationTokens();
    const options: any = {
      url: URL_REFRESH_TOKENS,
      credentials: 'include',
      method: 'POST',
      data: JSON.stringify({ refresh: tokens.refresh_token }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const json_tokens = await this.service.request(options);
      const new_tokens: any = new AuthenticationTokens(json_tokens);
      this.authenticationActions_.storeAuthenticationTokens(new_tokens);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${new_tokens?.access_token}`,
        'X-CSRFToken': Cookies.get('csrftoken'),
      };
      return callback(headers);
    } catch (error: any) {
      console.error(error);
      if (
        error?.code !== 'ERR_NETWORK' &&
        (error?.response?.status ? error?.response?.status === 401 : false)
      ) {
        localStorage.clear();
        const path = `${LOGIN_PATH}?next=${window.location.pathname}`;
        return window.open(path, '_self');
      }
    }
  }

  async get(path: string, headers: AxiosRequestHeaders) {
    const callback = (cHeaders: any) =>
      this.get(path, { ...headers, ...cHeaders });
    return this.service
      .request({
        method: 'GET',
        url: path,
        responseType: 'json',
        headers: headers,
      })
      .catch((error) => this.handleError(error, callback));
  }

  async patch(path: string, payload: any, headers: any) {
    const callback = (cHeaders: any) =>
      this.patch(path, payload, {
        ...cHeaders,
        'Content-Type': headers['Content-Type'],
      });
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .catch((error) => this.handleError(error, callback));
  }

  async post(path: string, payload: any, headers: any) {
    const callback = (cHeaders: any) =>
      this.post(path, payload, {
        ...cHeaders,
        'Content-Type': headers['Content-Type'],
      });
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .catch((error) => this.handleError(error, callback));
  }

  async delete(path: string, headers: AxiosRequestHeaders) {
    const callback = (cHeaders: any) =>
      this.delete(path, { ...headers, ...cHeaders });
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        headers: headers,
      })
      .catch((error) => this.handleError(error, callback));
  }

  loopObjectErrorKeys = (results: any) => {
    let alert_message = '';

    for (const key in results) {
      if (Object.prototype.hasOwnProperty.call(results, key)) {
        const value = results[key];

        if (typeof value === 'object') {
          // If the value is an object, it may contain nested errors
          const nestedErrors = this.loopObjectErrorKeys(value);
          if (nestedErrors) {
            alert_message += `${nestedErrors} `;
          }
        } else if (Array.isArray(value)) {
          // If the value is an array, join the error messages
          alert_message += `${value.join(', ')} `;
        } else {
          // If the value is a simple error message, append it
          alert_message += `${value} `;
        }
      }
    }

    return alert_message.trim();
  };

  extractResponseError = (error: AxiosError) => {
    let alert_message = '';
    const results: any = error.response?.data;
    if (results) {
      if (results.constructor === Array && results.length > 0) {
        alert_message = results[0];
      } else if (typeof results === 'object') {
        if (results.detail) {
          alert_message = results.detail;
        } else if (results.non_field_errors) {
          alert_message = results.non_field_errors;
        } else if (Object.keys(results).length > 0) {
          alert_message += this.loopObjectErrorKeys(results);
        } else {
          alert_message = results.toString();
        }
      } else if (typeof results === 'string') {
        alert_message = results;
      } else {
        alert_message = error.message;
      }
    } else {
      alert_message = error?.message ?? 'Uknown error occurred';
      alert_message += '. Please try again after some time.';
    }
    return alert_message;
  };
}
