import Cookies from 'js-cookie';
import _ from 'lodash';
import { AuthActions } from './AuthActions';
import { PublicApi } from './PublicAPI';

export class PrivateApi extends PublicApi {
  private readonly authenticatedHeaders: any;
  private readonly multipartHeaders: any;

  constructor() {
    super();
    const accountActions = new AuthActions();
    const tokens = accountActions.retrieveAuthenticationTokens();
    this.authenticatedHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens?.access_token}`,
      'X-CSRFToken': Cookies.get('csrftoken'),
    };
    const multipart_headers = _.merge({}, this.getAuthenticatedHeaders());
    multipart_headers['Content-Type'] = 'multipart/form-data; boundary=--';
    this.multipartHeaders = multipart_headers;
  }

  getAuthenticatedHeaders() {
    return this.authenticatedHeaders;
  }

  getMultipartHeaders() {
    return this.multipartHeaders;
  }
}
