import Cookies from 'js-cookie';
import {
  URL_EMAIL_PASSWORD_LOGIN,
  URL_LOGOUT,
  URL_REFRESH_TOKENS,
} from '../../utils/Constants';
import { AuthActions } from '../AuthActions';
import { PublicApi } from '../PublicAPI';
import { AuthenticationTokens } from '../models/AccountModels';

export class AuthenticationApi extends PublicApi {
  loginWithEmailAndPassword(
    state: any,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string
    ) => void
  ) {
    this.post(
      URL_EMAIL_PASSWORD_LOGIN,
      { ...state },
      this.getUnauthenticatedHeaders()
    )
      .then((response: any) => {
        if (response.hash) {
          callback(false, response);
        } else {
          const tokens: any = new AuthenticationTokens(response);
          callback(true, tokens);
        }
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  refreshTokens(
    refresh: string,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string
    ) => void
  ) {
    this.post(URL_REFRESH_TOKENS, { refresh }, this.getUnauthenticatedHeaders())
      .then((response: any) => {
        const tokens: any = new AuthenticationTokens(response);
        callback(true, tokens);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  logout(
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string
    ) => void
  ) {
    const accountActions = new AuthActions();
    const tokens = accountActions.retrieveAuthenticationTokens();
    const authenticatedHeaders: any = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens?.access_token}`,
      'X-CSRFToken': Cookies.get('csrftoken'),
    };
    this.post(URL_LOGOUT, {}, authenticatedHeaders)
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }
}
