export class User {
  id: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  is_staff?: boolean;
  is_superuser?: boolean;
  initials: string;
  password?: string;
  password_confirm?: string;
  table_settings?: any;
  tokens?: any;
  is_mfa?: any;
  qr_code?: any;
  last_active?: string;
  tags_notification_enabled?: boolean;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    this.full_name = parameters.full_name;
    this.email = parameters.email;
    this.is_staff = parameters.is_staff;
    this.is_superuser = parameters.is_superuser;
    this.initials = parameters.initials;
    this.table_settings = parameters.table_settings;
    this.tokens = parameters.tokens;
    this.is_mfa = parameters.is_mfa;
    this.qr_code = parameters.qr_code;
    this.last_active = parameters.last_active;
    this.tags_notification_enabled = parameters.tags_notification_enabled;
  }
}

export class AuthenticationTokens {
  access_token;
  refresh_token;
  is_mfa;

  constructor(parameters: any) {
    this.access_token = parameters.access;
    this.refresh_token = parameters.refresh;
    this.is_mfa = parameters.is_mfa;
  }
}
